import React, { useLayoutEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import eventImg from "../../assests/images/event-img.png";
import { Link } from "react-router-dom";
import { useWindowSize } from "../../utils/getWindowSize";

function Workshop() {
  const { width } = useWindowSize();
  // const [size, setSize] = useState([]);
  // useLayoutEffect(() => {
  //   function updateSize() {
  //     setSize([window.innerWidth, window.innerHeight]);
  //   }
  //   window.addEventListener("resize", updateSize);
  //   updateSize();
  //   return () => window.removeEventListener("resize", updateSize);
  // }, []);
  // console.log("Size", size);

  return (
    <div className="workshop-container">
      <center>
        <Link to="/events">
          <button className="workshop-button">Workshops and Events</button>
        </Link>
      </center>
      {width < 768 ? (
        <div className="workshop-img-container">
          <img src={eventImg} alt="workshop-img" />
        </div>
      ) : (
        <div className="workshop-middle">
          <div className="work-left">
            <h1>2024</h1>
            <h3>Learning Exchange</h3>
          </div>
          <div className="work-mid">
            <p>On the way</p>
            <hr className="work-dotted-line" />
          </div>
          <div className="work-right">
            <h1>1 - 3</h1>
            <h3>OCTOBER</h3>
            {/* <button className="workshop-button know-btn">Know more</button> */}
          </div>
        </div>
      )}
      <div className="workshop-bottom">
        <Link to="/events/international-learning-exchange-2023">
          <button className="workshop-button know-btn">Know more</button>
        </Link>
      </div>
    </div>

    // <div style={{ marginBottom: 40, overflow: "hidden", marginTop: 20 }}>
    //   <center>
    //     <Link to="/events">
    //       <button className="home-page-button home-workshop-button">
    //         Workshops and Events
    //       </button>
    //     </Link>
    //   </center>
    //   {size.length > 0 && size[0] < 768 ? (
    //     <Row>
    //       <img style={{ marginTop: 20 }} src={eventImg} alt="" />
    //     </Row>
    //   ) : (
    //     <Row style={{ alignItems: "center" }}>
    //       <Col md="2" style={{ display: "flex", flexDirection: "row" }}>
    //         <div className="home-workshop-title">
    //           <p className="home-workshop-title-year">2024</p>
    //           <p className="home-workshop-title-name">Learning Exchange</p>
    //         </div>
    //       </Col>
    //       <Col md="8">
    //         <hr className="home-workshop-dotted-line" />
    //       </Col>
    //       <Col md="2">
    //         <div className="home-workshop-title">
    //         <p className="home-workshop-title-year">1 - 3</p>
    //         <p className="home-workshop-title-name">OCTOBER</p>
    //           {/* <p className="home-workshop-date">1 - 3 October</p> */}
    //         </div>
    //         <Link to="/events/international-learning-exchange-2023">
    //           <button className="home-page-button home-workshop-button1">
    //             Know more
    //           </button>
    //         </Link>
    //       </Col>
    //     </Row>
    //   )}
    // </div>
  );
}

export default Workshop;
