// import React, { useLayoutEffect, useRef, useState } from "react";
// import { Carousel } from "react-responsive-carousel";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import demoUser from "../../assests/images/demo-user.png";
// import demoPubs from "../../assests/images/demo-pubs.png";
import leftArrow from "../../assests/images/leftArrow.png";
// import demoImage2 from "../../assests/images/demo-projects2.png";
import rightArrow from "../../assests/images/rightArrow.png";
// import demoImage from "../../assests/images/demo-project-1.png";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// const ProjectCarousel = () => {
//   const [size, setSize] = useState([]);
//   useLayoutEffect(() => {
//     function updateSize() {
//       setSize([window.innerWidth, window.innerHeight]);
//     }
//     window.addEventListener("resize", updateSize);
//     updateSize();
//     return () => window.removeEventListener("resize", updateSize);
//   }, []);
//   console.log("Size", size);
//   const carouselRef = useRef(null);
//   const items = [{}, {}, {}, {}, {}];
//   const goToNext = () => {
//     if (carouselRef.current) {
//       carouselRef.current.increment();
//     }
//   };

//   const goToPrev = () => {
//     if (carouselRef.current) {
//       carouselRef.current.decrement();
//     }
//   };
//   const getCenterSlidePercentage = () => {
//     if (size[0] < 600) {
//       return 100; // One slide
//     } else if (size[0] < 900) {
//       return 50; // Two slides
//     } else {
//       return 33.33; // Three slides
//     }
//   };

//   const getTheJustifyContentProps = () => {
//     if (size[0] < 600) {
//       return "flex-end"; // One slide
//     } else if (size[0] < 900) {
//       return "space-between"; // Two slides
//     } else {
//       return "center"; // Three slides
//     }
//   };

//   return (
//     <div
//       style={{
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//       }}
//     >
//       <button style={{ border: "none", background: "none" }} onClick={goToPrev}>
//         <img style={{ width: 15 }} src={leftArrow} alt="left-arrow" />
//       </button>
//       <Carousel
//         showThumbs={true}
//         showStatus={false}
//         showArrows={false}
//         infiniteLoop={true}
//         ref={carouselRef}
//         centerMode={true}
//         centerSlidePercentage={getCenterSlidePercentage()} // Set to display three slides at a time
//       >
//         <div className="home-pubs-division">
//           <Col md="3" className="home-project-column">
//             <Link to="/our-work/jharkhand/jharfra-launch">
//               <img
//                 className="home-project-image"
//                 src={
//                   "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/jharfra.png"
//                 }
//                 alt="alt-img"
//               />
//             </Link>
//           </Col>
//           <Col md="3" className="home-project-column">
//             <Link
//               style={{ textDecoration: "none" }}
//               to="/our-work/jharkhand/jharfra-launch"
//             >
//               <div className="home-project-desc">
//                 <p className="home-project-desc-title">Launch of JharFRA</p>
//                 <p className="home-project-desc-description">
//                   The JharFRA application has been adopted by GOJ’s Abua Bir
//                   Abua Dishom Campaign, to file CFR claims for over 1 lakh
//                   individuals through state-civil society collaboration. {" "}
//                 </p>
//               </div>
//             </Link>
//           </Col>
//         </div>
//         <div className="home-pubs-division">
//           <Col md="3" className="home-project-column">
//             <Link to="/our-work/odisha/jungle-rani">
//               <img
//                 className="home-project-image"
//                 src={
//                   "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/head_page/empowering+women.png"
//                 }
//                 alt="alt-img"
//               />
//             </Link>
//           </Col>
//           <Col md="3" className="home-project-column">
//             <Link
//               style={{ textDecoration: "none" }}
//               to="/our-work/odisha/jungle-rani"
//             >
//               <div className="home-project-desc">
//                 <p className="home-project-desc-title" style={{ padding: 1 }}>
//                   Empowering Women-Led Enterprises
//                 </p>
//                 <p className="home-project-desc-description">
//                   Women-led FPCs and primary collectors from Gumla and Ranchi,
//                   Jharkhand, formed their first direct industry partnership by
//                   selling Sal seeds to Swedish company AAK.
//                 </p>
//               </div>
//             </Link>
//           </Col>
//         </div>
//         <div className="home-pubs-division">
//           <Col md="3" className="home-project-column">
//             <Link to="/our-work/himachal-pradesh/ncount-training">
//               <img
//                 className="home-project-image"
//                 src={
//                   "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/head_page/ncount-main-page.png"
//                 }
//                 alt="alt-img"
//               />
//             </Link>
//           </Col>
//           <Col md="3" className="home-project-column">
//             <Link
//               style={{ textDecoration: "none" }}
//               to="/our-work/himachal-pradesh/ncount-training"
//             >
//               <div className="home-project-desc">
//                 <p className="home-project-desc-title">
//                   Harnessing nCount for Sustainability
//                 </p>
//                 <p className="home-project-desc-description">
//                   The Himachal Pradesh Forest Department's use of nCount will
//                   inventory herbs and medicinal plants, enhancing scientific
//                   knowledge and guiding policies for ecology, biodiversity, and
//                   community needs.
//                 </p>
//               </div>
//             </Link>
//           </Col>
//         </div>
//         <div className="home-pubs-division">
//           <Col md="3" className="home-project-column">
//             <Link to="/our-work/Goa/Bamboo Economy Rising: Building Bioenergy Hubs and Women-led Enterprises through MoU signed with the Government of Goa">
//               <img
//                 className="home-project-image"
//                 src={
//                   "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/head_page/Gao-mou.png"
//                 }
//                 alt="alt-img"
//               />
//             </Link>
//           </Col>
//           <Col md="3" className="home-project-column">
//             <Link
//               style={{ textDecoration: "none" }}
//               to="/our-work/Goa/Bamboo Economy Rising: Building Bioenergy Hubs and Women-led Enterprises through MoU signed with the Government of Goa"
//             >
//               <div className="home-project-desc">
//                 <p className="home-project-desc-title">
//                   ISB-Government of Goa MoU
//                 </p>
//                 <p className="home-project-desc-description">
//                   IOFE and the Goa government have signed an MoU to develop
//                   evidence-based policies, empowering forest communities and
//                   women-led enterprises through technology, sustainability, and
//                   market linkages.{" "}
//                 </p>
//               </div>
//             </Link>
//           </Col>
//         </div>
//       </Carousel>
//       <button style={{ border: "none", background: "none" }} onClick={goToNext}>
//         <img style={{ width: 15 }} src={rightArrow} alt="rgt-arrow" />
//       </button>
//     </div>
//   );
// };

// export default ProjectCarousel;

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <img src={leftArrow} alt="prev-arrow" style={{ width: 20 }} />
    </div>
  );
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <img src={rightArrow} alt="next-arrow" style={{ width: 20 }} />
    </div>
  );
}

export default function ProjectCarousel() {
  const settings = {
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <Slider {...settings}>
      <div className="home-pubs-division">
        <Col md="3" className="home-project-column img-col">
          <Link to="/our-work/jharkhand/jharfra-launch">
            <img
              className="home-project-image"
              src={
                "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/work/jharfra.png"
              }
              alt="alt-img"
            />
          </Link>
        </Col>
        <Col md="3" className="home-project-column desc-col">
          <Link
            style={{ textDecoration: "none" }}
            to="/our-work/jharkhand/jharfra-launch"
          >
            <div className="home-project-desc">
              <p className="home-project-desc-title">Launch of JharFRA</p>
              <p className="home-project-desc-description">
                The JharFRA application has been adopted by GOJ’s Abua Bir Abua
                Dishom Campaign, to file CFR claims for over 1 lakh individuals
                through state-civil society collaboration. {" "}
              </p>
            </div>
          </Link>
        </Col>
      </div>
      <div className="home-pubs-division">
        <Col md="3" className="home-project-column img-col">
          <Link to="/our-work/odisha/jungle-rani">
            <img
              className="home-project-image"
              src={
                "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/head_page/empowering+women.png"
              }
              alt="alt-img"
            />
          </Link>
        </Col>
        <Col md="3" className="home-project-column desc-col">
          <Link
            style={{ textDecoration: "none" }}
            to="/our-work/odisha/jungle-rani"
          >
            <div className="home-project-desc">
              <p className="home-project-desc-title" style={{ padding: 1 }}>
                Empowering Women-Led Enterprises 
              </p>
              <p className="home-project-desc-description">
                Women-led FPCs and primary collectors from Gumla and Ranchi,
                Jharkhand, formed their first direct industry partnership by
                selling Sal seeds to Swedish company AAK.
              </p>
            </div>
          </Link>
        </Col>
      </div>
      <div className="home-pubs-division">
        <Col md="3" className="home-project-column img-col">
          <Link to="/our-work/himachal-pradesh/ncount-training">
            <img
              className="home-project-image"
              src={
                "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/head_page/ncount-main-page.png"
              }
              alt="alt-img"
            />
          </Link>
        </Col>
        <Col md="3" className="home-project-column desc-col">
          <Link
            style={{ textDecoration: "none" }}
            to="/our-work/himachal-pradesh/ncount-training"
          >
            <div className="home-project-desc">
              <p className="home-project-desc-title">
                Harnessing nCount for Sustainability
              </p>
              <p className="home-project-desc-description">
                The Himachal Pradesh Forest Department's use of nCount will
                inventory herbs and medicinal plants, enhancing scientific
                knowledge and guiding policies for ecology, biodiversity, and
                community needs.
              </p>
            </div>
          </Link>
        </Col>
      </div>
      <div className="home-pubs-division">
        <Col md="3" className="home-project-column img-col">
          <Link to="/our-work/Goa/Bamboo Economy Rising: Building Bioenergy Hubs and Women-led Enterprises through MoU signed with the Government of Goa">
            <img
              className="home-project-image"
              src={
                "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/head_page/Gao-mou.png"
              }
              alt="alt-img"
            />
          </Link>
        </Col>
        <Col md="3" className="home-project-column desc-col">
          <Link
            style={{ textDecoration: "none" }}
            to="/our-work/Goa/Bamboo Economy Rising: Building Bioenergy Hubs and Women-led Enterprises through MoU signed with the Government of Goa"
          >
            <div className="home-project-desc">
              <p className="home-project-desc-title">
                ISB-Government of Goa MoU
              </p>
              <p className="home-project-desc-description">
                IOFE and the Goa government have signed an MoU to develop
                evidence-based policies, empowering forest communities and
                women-led enterprises through technology, sustainability, and
                market linkages.{" "}
              </p>
            </div>
          </Link>
        </Col>
      </div>
    </Slider>
  );
}
