import React, { useLayoutEffect, useState } from "react";
import mapIcon from "../../assests/logo/mapIcon.png";
import bg from "../../assests/images/eventsBg.png";
import arrowIcon from "../../assests/logo/arrowIcon.png";
import bg_video from "../../assests/images/Background_wave.mp4";
import { Col, Row, Input } from "reactstrap";
import { Link } from "react-router-dom";
import eventsData from "../../utils/EventsData";
import Slider from "react-slick";

function Events() {
  const [size, setSize] = useState([]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  console.log("Size", size);

  const settings = {
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="home-events-section">
      <div className="events-container">
        <div className="events-slider">
          <Slider {...settings} className="custom-slider-events">
            {eventsData.map((event) => {
              return (
                <Link style={{ textDecoration: "none !important" }}>
                  <div className="event-slider-card">
                    <div className="card-info">
                      <span>{event.isUpcoming ? "Upcoming" : <>&nbsp;</>}</span>
                      <h1>
                        {event?.dateStart}
                        {event?.dateEnd !== "" && `-${event?.dateEnd}`}
                      </h1>
                      <h6>
                        {event?.Month}’{event?.year}
                      </h6>
                      <p>{event?.title}</p>
                    </div>
                    <div className="card-location-info">
                      <div className="card-location-icon">
                        <img src={mapIcon} className="" alt="map-icon" />
                        <p className="event-loc">Mohali</p>
                      </div>
                      <img src={arrowIcon} className="" alt="arrow-icon" />
                    </div>
                  </div>
                </Link>
              );
            })}
          </Slider>
        </div>
        <div className="events-form">
          <p className="home-event-title-name">Workshops and Events</p>
          <p className="home-event-title-desc">
            Don't Miss Out on Our Dynamic Events and Workshops!
          </p>
          <Input
            className="event-subscription-input"
            placeholder="Your Email ID"
          />
          <button className="event-subscription-button">Subscribe</button>
        </div>
      </div>
      {/* <img src={bg} className="background-video" alt="wave" /> */}
    </div>
  );
}

export default Events;
