import React, { useLayoutEffect, useState } from "react";
import Header from "../../components/Header2";
import { Col, Row } from "reactstrap";
import bg from "../../assests/images/work_bg.png";
import mobBg from "../../assests/images/workBG.png";
import mapImage from "../../assests/images/map.png";
import { Tooltip } from "reactstrap";
import pinImage from "../../assests/images/pin.png";
import { Link, useNavigate } from "react-router-dom";
import locationIcon from "../../assests/icons/Location.png";
import Dropdown from "../../utils/CustomDropdown";
import DropDown from "../../utils/CustomDropdown";
import OurWorkCardsSection from "./WorkCards";
import { useWindowSize } from "../../utils/getWindowSize";

function Home() {
  const { width } = useWindowSize();
  return (
    <div className="work-landing-page">
      <Header activeLink="1" background={false} modified={true} />
      <div className="work-top-container">
        <div className="map-container-main">
          <img src={mapImage} className="map-img-main" alt="map-img" />
          <Link to="/our-work/Odisha">
            <img src={locationIcon} className="map-pin-odisha" alt="loc-img" />
          </Link>
          <Link to="/our-work/andhra-pradesh">
            <img src={locationIcon} className="map-pin-andhra" alt="loc-img" />
          </Link>
          <Link to="/our-work/jharkhand">
            <img
              src={locationIcon}
              className="map-pin-jharkhand"
              alt="loc-img"
            />
          </Link>
          <Link to="/our-work/himachal-pradesh">
            <img src={locationIcon} className="map-pin-hp" alt="loc-img" />
          </Link>
          <Link to="/our-work/goa">
            <img src={locationIcon} className="map-pin-goa" alt="loc-img" />
          </Link>
          <Link to="/our-work/maharashtra">
            <img src={locationIcon} className="map-pin-mh" alt="loc-img" />
          </Link>
        </div>
        <div className="work-landing-top">
          <div className="top-details">
            <div className="top-details-info">
              <p>Not to the scale. Just for the illustration purpose*</p>
            </div>
            <div className="top-details-dropdown">
              <h2>Pick up your area of interest</h2>
              <p>
                Living laboratories as part of ITFF serve as regionally embedded
                expansive demonstration sites designed to illustrate the
                transformative potential of forests in driving positive
                socio-economic change. These laboratories operate as pilot
                projects, integrating sustainability principles, scalable
                practices, and advanced technologies to foster holistic natural
                resource governance.
              </p>
              <DropDown />
            </div>
          </div>
        </div>
        {width > 768 && <div className="work-landing-bottom"></div>}
      </div>
      <OurWorkCardsSection />
    </div>
  );

  // return (
  //   <div style={{ overflow: "hidden" }}>
  //     {size.length > 0 && size[0] < 768 ? (
  //       <div className="mob-work-home-section">
  //         <img src={mobBg} className="mob-background-video-1" />
  //         <Header modified={false} background={false} />
  //         <div className="mob-work-map-container">
  //           <div style={{ position: "relative" }}>
  //             <Dropdown
  //               inputStyle={inputStyle}
  //               placeholderStyle={placeholderStyle}
  //               onChange={handleChange}
  //               options={organizations}
  //               placeholder={"Our living laboratories of IOFE"}
  //             />
  //             <div className="map-image-text">
  //               <p className="mob-map-image-title">
  //                 Pick up your area of interest
  //               </p>
  //               <p className="mob-map-image-desc">
  //                 Living laboratories as part of IOFE serve as regionally
  //                 embedded expansive demonstration sites designed to illustrate
  //                 the transformative potential of forests in driving positive
  //                 socio-economic change. These laboratories operate as pilot
  //                 projects, integrating sustainability principles, scalable
  //                 practices, and advanced technologies to foster holistic
  //                 natural resource governance.
  //               </p>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     ) : (
  //       <div className="work-home-section">
  //         <img src={bg} className="background-video-1" />
  //         <Header activeLink="1" background={false} modified={true} />
  //         {/* <div className='work-map-dropdown-div'>
  //         <Dropdown inputStyle={inputStyle}  placeholderStyle={placeholderStyle} onChange={handleChange} options={organizations} placeholder={"Our living laboratories of IOFE"}/>
  //           </div> */}
  //         <div className="work-map-container">
  //           <div style={{ position: "relative" }}>
  //             <img src={mapImage} className="work-map-image" />
  //             <Link to="/our-work/Odisha">
  //               <a id="odisha">
  //                 <img src={locationIcon} className="map-pin-odisha" />
  //               </a>
  //             </Link>
  //             <Link to="/our-work/andhra-pradesh">
  //               <img src={locationIcon} className="map-pin-andhra" />
  //             </Link>
  //             <Link to="/our-work/jharkhand">
  //               <img src={locationIcon} className="map-pin-jharkhand" />
  //             </Link>
  //             <Link to="/our-work/himachal-pradesh">
  //               <img src={locationIcon} className="map-pin-hp" />
  //             </Link>
  //             <Link to="/our-work/goa">
  //               <img src={locationIcon} className="map-pin-goa" />
  //             </Link>
  //             <Link to="/our-work/maharashtra">
  //               <img src={locationIcon} className="map-pin-mh" />
  //             </Link>
  //             {/* <Link to="/our-work/andhra-pradesh"> */}
  //             <img src={locationIcon} className="map-pin-ap" />
  //             {/* </Link> */}
  //           </div>
  //           <div className="map-image-text">
  //             <p className="map-image-title">Pick up your area of interest</p>
  //             <p className="map-image-desc">
  //               Living laboratories as part of IOFE serve as regionally embedded
  //               expansive demonstration sites designed to illustrate the
  //               transformative potential of forests in driving positive
  //               socio-economic change. These laboratories operate as pilot
  //               projects, integrating sustainability principles, scalable
  //               practices, and advanced technologies to foster holistic natural
  //               resource governance.
  //             </p>
  //             <Dropdown
  //               inputStyle={inputStyle1}
  //               placeholderStyle={placeholderStyle}
  //               onChange={handleChange}
  //               options={organizations}
  //               placeholder={"Our living laboratories of IOFE"}
  //             />
  //             <br></br>
  //           </div>
  //         </div>
  //       </div>
  //     )}
  //     <Home_landing_page />
  //   </div>
  // );
}

export default Home;
